import * as React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Header, TransitionMask } from '@components/organisms'
import { Grid, GridItem } from '@components/grid'
import { PageProps } from '@types'
import { shuffle } from 'lodash'

const VaultPage = ({data: { 
  sanityPage: {
    gridItems,
  }
}}: PageProps) => {
  const [shuffledGridItems, setShuffledGridItems] = React.useState<Array<any>>(shuffle(gridItems.filter(p => !!p).filter((_, i) => i > 0)))
  React.useEffect(() => {
    setShuffledGridItems(shuffle(shuffledGridItems.map((gridItem, i) => {
      const columns = Math.ceil(Math.random() * 3) + 1
      const columnStart = Math.ceil(Math.random() * (6 - columns)) + 1
      return {
        ...gridItem, 
        columns: (i == 0) ? gridItem.columns : columns,
        columnStart: (i == 0) ? gridItem.columnStart : columnStart,
      }
    })))
  }, [])
  return (
    <>
      <Header />
      <main>
        <Grid>
          <HeaderGridItem {...gridItems[0]} />
          {shuffledGridItems.map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
        </Grid>
      </main>
      <TransitionMask />
    </>
  )
}



const HeaderGridItem = styled(props => <GridItem {...props} />)`
  @media only screen and (max-width: 767px) {
    padding: 15vw 0 10vw 0;
  }
`


export const query = graphql`
  query VaultPageQuery {
    sanityPage(slug: {current: {eq: "vault"}}) {
      ...pageFields
    }
  }
`



export default VaultPage